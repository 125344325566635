import { Injectable } from '@angular/core';
import { appServices, BillingSubscription, selectSubscriptions, ServiceInfo, setShowLoader } from '@topseller/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ServiceInitialModalComponent } from '../../../shared/components/service-initial-modal';
/**
 * Guard который проверяет активирован ли сервис, т.е. заходил ли пользователь на страницу сервиса.
 * Показывает модальное окно с предложением активировать сервис, если пользователь не активировал сервис.
 * */
@Injectable()
export class ServiceActivatedGuard {
  constructor(private store: Store, private dialog: MatDialog) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const serviceId = route.data['serviceId'];
    return this.store.select(selectSubscriptions).pipe(
      switchMap((subscriptions: BillingSubscription[]) => {
        const showModal = this.checkNeedShowModalForService(
          serviceId,
          subscriptions
        );
        if (!showModal) {
          this.store.dispatch(setShowLoader({ showLoader: true }));
          return of(true);
        }
        const service = appServices.find(
          (x: ServiceInfo) => x.id === serviceId
        )!;
        const dialogRef: MatDialogRef<ServiceInitialModalComponent> =
          this.dialog.open(ServiceInitialModalComponent, {
            backdropClass: 'ts-modal-backdrop',
            panelClass: ['ts-modal-panel', 'ts-modal-panel-lg'],
            id: 'ts-modal',
            data: {
              title: service.initialModalTitle ?? service.name,
              serviceId: service.id,
            },
          });
        return dialogRef.afterClosed().pipe(
          tap((res) => {
            if (res) {
              this.store.dispatch(setShowLoader({ showLoader: true }));
            }
          })
        );
      })
    );
  }

  private checkNeedShowModalForService(
    serviceId: string,
    subscriptions: BillingSubscription[]
  ): boolean {
    const subscriptionInfo = subscriptions.find(
      (s: BillingSubscription) => s.product!.identifier === serviceId
    );

    if (serviceId === 'connect') {
      const hubSubscription = subscriptions.find(
        (x) => x.product?.identifier === 'hub'
      );
      if (!hubSubscription) {
        return false;
      }
      return !hubSubscription?.tariff;
    }

    if (!subscriptionInfo) {
      return false;
    }
    return !subscriptionInfo?.tariff;
  }
}
