<div class="edit-comment" *ngIf="editBlockShow$ | async">
  <div
    class="ts-icon ts-icon-actions-Edit text-2lg ts-text-icon-info-primary-color"
  ></div>
  <div class="flex-grow-1 overflow-hidden">
    <div class="ts-text-info-color ts-caption-strong">
      Редактирование комментария
    </div>
    <div class="text-truncate">{{ commentForEdit?.text }}</div>
  </div>
  <div
    class="cursor-pointer ts-icon ts-icon-actions-Close text-2lg ts-text-icon-neutral-color"
    (click)="cancelEdit()"
  >
    ️
  </div>
</div>
<ts-input-comment
  [formControl]="commentsFormControl"
  (send)="save()"
  [uploadFilesFn]="uploadFilesApi"
  [attachedFiles]="attachedFiles"
  (filesUpdated)="updateAttachedFiles($event)"
></ts-input-comment>
<div class="mt-4 ts-subtitle mb-3">Все комментарии ({{ commentsCount }})</div>

<!--TODO: calc max height to the bottom of the page-->
<div
  class="d-flex flex-column gap-3"
  *ngIf="employee$ | async as employee"
  style="max-height: calc(100dvh - 525px)"
>
  <ts-scroll>
    <div class="d-flex flex-column gap-3" *ngIf="comments?.length; else empty">
      <app-comment
        @commentAnimation
        *ngFor="let comment of comments; trackBy: identify"
        [comment]="comment"
        [isEditing]="commentForEdit?.id === comment.id"
        [employeeId]="employee"
        (edit)="onEdit($event)"
        (delete)="onCommentDelete($event)"
      ></app-comment>
    </div>
  </ts-scroll>
  <ng-template #empty>
    <div class="comments-list__empty">
      <img src="/assets/img/hub/comment/comments-empty.svg" />
    </div>
  </ng-template>
</div>
