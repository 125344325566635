import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  finalize,
  map,
  Observable, of,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import {
  Commentary,
  CommentService,
  HubEntity, ItemRolesConfig,
  Paginated,
} from '../../../data/';
import { EDIT_ITEM_ROLES_CONFIG, ENTITY_NAME } from '../../tokens';

import { commentAnimation } from './animations';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import {
  allowedFilesFormats, AuthService,
  ConfirmDialogComponent,
  File as AttachedFile,
  FileService, ROLES,
  selectEmployee, setShowLoader
} from '@topseller/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-comments-block',
  templateUrl: './comments-block.component.html',
  styleUrls: ['./comments-block.component.scss'],
  animations: [commentAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsEntityCommentListComponent implements OnInit, OnDestroy {
  @Input() public entityId?: string;

  public formats = allowedFilesFormats;

  private destroy$: Subject<void> = new Subject<void>();

  public comments: Commentary[] = [];
  public loading = false;

  public editBlockShow$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public commentForEdit: Commentary | null = null;
  public commentsFormControl = new FormControl();
  public attachedFiles: AttachedFile[] = [];

  get valueFromInput(): string {
    if (this.commentsFormControl.value) {
      return this.commentsFormControl.value;
    }
    return '';
  }

  public employee$: Observable<any> = this.store.select(selectEmployee);

  uploadFilesApi = (files: File[]) =>
    this.fileService.postAppFileBulkcreateForm('commentary', files);

  get commentsCount(): number {
    return this.comments?.length ?? 0;
  }

  constructor(
    @Optional() @Inject(ENTITY_NAME) private readonly entityName: HubEntity,
    private readonly commentService: CommentService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastrService: ToastrService,
    private fileService: FileService,
    private dialog: MatDialog,
    private store: Store,
    @Inject(EDIT_ITEM_ROLES_CONFIG) public editItemRoles:  ItemRolesConfig,
    private authService: AuthService
  ) {
    if (!this.entityName) {
      throw new Error('Entity name for comments should be defined!');
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public identify(_: number, item: Commentary): string {
    return item.id!;
  }

  public ngOnInit(): void {
    // Проверяем наличие роли перед запросом к API
    this.authService.employeeHasRole(ROLES.ROLE_COMMENTARY_VIEW).pipe(
      switchMap(hasRole => {
        // Если роль есть, выполняем запрос к API
        if (hasRole) {
          return this.commentService.getAppCommentaryIndex(this.entityName, this.entityId || '');
        }
        // Если роли нет, возвращаем пустой массив
        else {
          return of({ items: [], pagination:{total:0} });
        }
      }),
      takeUntil(this.destroy$),
      map(({ items }: Paginated<Commentary>) => items)
    )
      .subscribe((items: Commentary[]) => {
        this.comments = items;
        this.changeDetectorRef.markForCheck();
      });
  }

  public save() {
    if (!this.valueFromInput.trim() && !this.attachedFiles.length) {
      this.toastrService.error(
        'Для отправки комментария необходимо заполнить поле или прикрепить файл'
      );
      return;
    }

    this.loading = true;
    this.store.dispatch(setShowLoader({ showLoader: true }));
    if (this.commentForEdit) {
      this.editComment();
      return;
    }

    this.createComment();
  }

  private editComment() {
    this.commentService
      .patchAppCommentaryUpdate(this.entityName, this.entityId || '', {
        ...this.commentForEdit,
        text: this.commentsFormControl.value,
        files: this.attachedFiles,
      })
      .pipe(
        take(1),
        finalize(() => {
          this.attachedFiles = [];
          this.editBlockShow$.next(false);
        })
      )
      .subscribe({
        next: (comment: Commentary) => {
          this.comments = this.comments!.map((comment: Commentary) => {
            if (comment.id === this.commentForEdit?.id) {
              return {
                ...comment,
                text: this.commentsFormControl.value,
                files: this.attachedFiles,
              };
            }
            return comment;
          });
          this.commentsFormControl.reset();
          this.commentForEdit = null;
          this.handleSuccess('Комментарий успешно отредактирован');
          this.store.dispatch(setShowLoader({ showLoader: false }));
        },
        error: (err: any) => {
          this.handleError(err);
        },
      });
  }

  private createComment() {
    this.commentService
      .postAppCommentaryCreate(this.entityName, this.entityId || '', {
        text: this.valueFromInput,
        data: [],
        files: this.attachedFiles,
      })
      .pipe(
        take(1),
        finalize(() => (this.attachedFiles = []))
      )
      .subscribe({
        next: (item: Commentary) => {
          this.comments?.unshift(item);
          this.commentsFormControl.reset();
          this.loading = false;
          this.changeDetectorRef.markForCheck();
          this.toastrService.success('Комментарий успешно создан');
          this.store.dispatch(setShowLoader({ showLoader: false }));
        },
        error: (err: any) => {
          this.handleError(err);
        },
      });
  }

  public onEdit(comment: Commentary): void {
    this.editBlockShow$.next(true);

    this.commentForEdit = comment;
    this.commentsFormControl.setValue(comment.text);

    comment.files?.forEach((file) => this.attachedFiles.push(file));
  }

  public cancelEdit() {
    this.editBlockShow$.next(false);
    this.commentsFormControl.reset();
    this.commentForEdit = null;
    this.attachedFiles = [];
  }

  public onCommentDelete(comment: Commentary) {
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: {
          title: 'Удаление коментария',
          content: `Вы уверены что хотите удалить комментарий "${comment.text}" ?`,
        },
        backdropClass: 'ts-modal-backdrop',
        panelClass: ['ts-modal-panel','ts-modal-panel-lg' ],
        width: '470px',
        id: 'ts-modal',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((result: boolean) => {
          return result
            ? this.commentService
                .deleteAppCommentaryDelete(
                  this.entityName,
                  this.entityId || '',
                  comment.id!
                )
                .pipe(take(1))
            : EMPTY;
        })
      )
      .subscribe({
        next: () => {
          const deleteCommentFn = (el: Commentary) => el.id !== comment.id;
          this.comments = this.comments!.filter(deleteCommentFn);
          this.handleSuccess('Комментарий удален');
        },
        error: (err: any) => {
          this.handleError(err);
        },
      });
  }

  updateAttachedFiles(files: AttachedFile[]) {
    this.attachedFiles = files;
  }

  private handleSuccess(message?: string) {
    this.loading = false;
    this.changeDetectorRef.markForCheck();

    if (message) {
      this.toastrService.success(message);
    }
  }

  private handleError(err: any) {
    this.toastrService.error(
      err?.errors?.length && err.errors[0].message
        ? err.errors[0].message
        : err?.message || 'Что-то пошло не так'
    );
    this.loading = false;
    this.changeDetectorRef.markForCheck();
    this.store.dispatch(setShowLoader({ showLoader: false }));
  }
}
