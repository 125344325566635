import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import {
  AccountSettingsApiService,
  AuthService,
  BillingApiService, CommonBatchDeleteApiService,
  DialogService,
  EmployeeSettingsService,
  FileService,
  LocalStorageService,
  NewsItemService,
  SecurityService,
  UserService,
} from './providers';
import { ConfirmDialogComponent } from './guards';
import { authReducer, commonReducer, CommonStateEffects } from './store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [
    StoreModule.forRoot({ auth: authReducer, application: commonReducer }, {}),
    EffectsModule.forFeature([CommonStateEffects]),
  ],
  providers: [
    UserService,
    AuthService,
    FileService,
    LocalStorageService,
    EmployeeSettingsService,
    BillingApiService,
    AccountSettingsApiService,
    SecurityService,
    DialogService,
    NewsItemService,
    CommonBatchDeleteApiService
  ],
})
export class TopsellerCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: TopsellerCoreModule) {
    if (parentModule) {
      throw new Error('TopsellerCoreModule is already loaded');
    }
  }
}
